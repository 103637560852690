import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const StyledBoxCenter = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding: "0",
  textAlign: "center",
  fontFamily: "Prompt",
  fontSize: "0.9rem",
  lineHeight: "0.9rem",

  [theme.breakpoints.down("sm")]: {
    lineHeight: "0.9rem",
  },
}));
