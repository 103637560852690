import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Grid, Link } from "@mui/material";
import _ from "lodash";
import Footer from "../../../components/footer/footer";
import {
  StyledContainer,
  StyledBoxCenter,
  StyledBoxLeft,
  StyledImageBanner,
  StyledImageDetail,
  StyledImageOther,
} from "./FilmFocus.style";
import InputTextField from "../../../components/input/inputTextField";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import { optionsPrefix } from "../../../utils/options";
import { checkPattenPhoneNumber, checkThaiLang } from "../../../utils/common";
import * as Cons from "./constant/FilmFocus.constant";
import { colors } from "../../../constants/colors";
import OptionsApi from "../../../api/option/option.api";
import CanpaignApi from "../../../api-campaign/campaign.api";
import { swalCustom, swalError } from "../../../components/notification/swal";
import Loading from "../../../components/loading/loading";
import ButtonCustom from "../../../components/button/buttonCustom";
import ModalFilmFocusFinish from "./components/ModalFilmFocusFinish";
import PartnerApi from "../../../api-partner/partner/partner.api";

interface ImageData {
  url: string;
  name?: string;
}

interface Data {
  banner?: string;
  name?: string;
  description: string;
  termsAndConditions?: string;
  notes?: string;
  image_url?: string;
  completeText?: string;
  image_other?: ImageData[];
  chatcone_process_type?: string;
}

const FilmFocus: React.FC = () => {
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const PARAMS_CHATCONE_TOKEN = params.get("token");
  const PARAMS_CHATCONE_SOCIAL_ID = params.get("id");
  const PARAMS_CHATCONE_CHANNEL_ID = params.get("channel_id");
  const PARAMS_CAMPAIGN_NAME = params.get("campaign_name") || "";

  const [errorMessage, setErrorMessage] = useState(Cons.ERROR_MESSAGE);
  const [data, setData] = useState<Data>({ description: "" });
  const [loading, setLoading] = useState(false);
  const [prefix, setPrefix] = useState<string>("");
  const [prefixOther, setPrefixOther] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [question1, setQuestion1] = useState<string>("");
  const [question2, setQuestion2] = useState<string>("");
  const [question3, setQuestion3] = useState<string>("");
  const [checkPhoneNumber, setCheckPhonenumber] = useState<boolean>(false);
  const [checkDigiPhoneNumber, setCheckDigiPhonenumber] = useState<boolean>(false);
  const [isShowFinishModal, setIsShowFinishMoal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string>("");
  const [optionAddress, setOptionAddress] = useState<any[]>([]);
  const [optionQuestion2, setOptionQuestion2] = useState<any[]>([]);

  const loadFilmFocusData = useCallback(() => {
    if (PARAMS_CAMPAIGN_NAME) {
      CanpaignApi.list({ name: PARAMS_CAMPAIGN_NAME }).then((res) => {
        if (res.status === 200) {
          const data = res?.data?.data[0] || {};
          setData(data);

          setModalContent(`<p>คุณ ได้ลงทะเบียนร่วมกิจกรรม</p>${data?.completeText}`);
        }
      });
    }
  }, [PARAMS_CAMPAIGN_NAME]);

  const loadAddress = useCallback(() => {
    OptionsApi.address().then((res) => {
      if (res.status === 200) {
        setOptionAddress(res?.data?.address);
      }
    });
  }, []);

  const loadQuestion2 = useCallback(() => {
    CanpaignApi.phoneBrand({ code: "PHONE_BRAND", is_active: true, is_visible: true }).then((res) => {
      if (res.status === 200) {
        setOptionQuestion2(res?.data?.result);
      }
    });
  }, []);

  useEffect(() => {
    loadFilmFocusData();
  }, [loadFilmFocusData]);

  useEffect(() => {
    loadAddress();
  }, [loadAddress]);

  useEffect(() => {
    loadQuestion2();
  }, [loadQuestion2]);

  useEffect(() => {
    if (phoneNumber && phoneNumber.length === 10) {
      const check = checkPattenPhoneNumber(phoneNumber);
      setCheckPhonenumber(!check);
      setCheckDigiPhonenumber(false);
    }
  }, [phoneNumber]);

  const handlePrefixChange = (value: any, reason: any) => {
    if (value) {
      if (value.value !== "อื่นๆ") {
        setPrefixOther("");
        setErrorMessage({ ...errorMessage, PREFIX: "", OTHER_PREFIX: "" });
        setPrefix(value.value);
      } else {
        setErrorMessage({ ...errorMessage, PREFIX: "" });
        setPrefix(value.value);
      }
    } else if (reason === "clear") setPrefix("");
  };

  const handlePrefixOtherChange = (event: any) => {
    setErrorMessage({ ...errorMessage, OTHER_PREFIX: "" });
    setPrefixOther(event.target.value);
  };

  const handleFirstNameChange = (event: any) => {
    setErrorMessage({ ...errorMessage, FIRSTNAME: "" });
    if (checkThaiLang(event.target.value)) {
      setFirstName(event.target.value);
    } else if (!checkThaiLang(event.target.value)) {
      setFirstName(event.target.value);
      setErrorMessage({ ...errorMessage, FIRSTNAME: "โปรดกรอกชื่อภาษาไทย" });
    } else if (event.target.value === "") {
      setFirstName("");
    }
  };

  const handleLastNameChange = (event: any) => {
    setErrorMessage({ ...errorMessage, LASTNAME: "" });
    if (checkThaiLang(event.target.value)) {
      setLastName(event.target.value);
    } else if (!checkThaiLang(event.target.value)) {
      setLastName(event.target.value);
      setErrorMessage({ ...errorMessage, LASTNAME: "โปรดกรอกนามสกุลภาษาไทย" });
    } else if (event.target.value === "") {
      setLastName("");
    }
  };

  const handlePhoneNumberChange = (event: any) => {
    if (event.target.value.length <= 10) {
      setPhoneNumber(event.target.value);
      setErrorMessage({ ...errorMessage, PHONE_NUMBER: "" });
    }
  };

  const handlePostalCodeChange = (event: any) => {
    if (event.target.value.length <= 5) {
      setErrorMessage({ ...errorMessage, POSTAL_CODE: "" });
      setPostalCode(event.target.value);
    }
  };

  const handleQuestion1Change = (event: any) => {
    const inputValue = event.target.value;
    const filteredValue = inputValue?.replace(/[\u0E00-\u0E7F]/g, ""); // กรองออกเฉพาะภาษาไทย
    setQuestion1(filteredValue);
    setErrorMessage({ ...errorMessage, QUESTION1: "" });
  };

  const handleQuestion2Change = (event: any, value: any, reason: any) => {
    if (value) {
      if (value.value !== "อื่นๆ") {
        setErrorMessage({ ...errorMessage, QUESTION2: "" });
        setQuestion2(value.value);
      } else {
        setErrorMessage({ ...errorMessage, QUESTION2: "" });
        setQuestion2(value.value);
      }
    } else if (reason === "clear") setQuestion2("");
  };

  const handleQuestion3Change = (event: any) => {
    setErrorMessage({ ...errorMessage, QUESTION3: "" });
    setQuestion3(event.target.value);
  };

  const onSubmit = async () => {
    const dataZipCode = optionAddress?.filter((val: any) => val?.zipcode === postalCode);

    if (
      !prefix ||
      !firstName ||
      !lastName ||
      !phoneNumber ||
      !postalCode ||
      checkPhoneNumber ||
      checkDigiPhoneNumber ||
      (prefix === "อื่นๆ" && !prefixOther) ||
      phoneNumber.length !== 10 ||
      postalCode.length !== 5 ||
      !checkThaiLang(firstName) ||
      !checkThaiLang(lastName) ||
      dataZipCode.length === 0 ||
      !question1 ||
      !question2 ||
      !question3
    ) {
      const msg: any = {
        PREFIX: !prefix ? "โปรดเลือกคำนำหน้าชื่อ" : "",
        FIRSTNAME: !firstName ? "โปรดกรอกชื่อ" : !checkThaiLang(firstName) ? "โปรดกรอกชื่อภาษาไทย" : "",
        LASTNAME: !lastName ? "โปรดกรอกนามสกุล" : !checkThaiLang(lastName) ? "โปรดกรอกนามสกุลภาษาไทย" : "",
        PHONE_NUMBER: !phoneNumber
          ? "โปรดกรอกเบอร์โทรศัพท์"
          : checkPhoneNumber
          ? "โปรดกรอกเบอร์โทรศัพท์ให้ถูกต้อง"
          : phoneNumber.length !== 10
          ? "ระบุจำนวน 10 ตัวอักษร"
          : "",
        POSTAL_CODE: !postalCode
          ? "โปรดกรอกรหัสไปรษณีย์"
          : dataZipCode.length === 0
          ? "รหัสไปรษณีย์ไม่ถูกต้อง"
          : postalCode.length !== 5
          ? "รหัสไปรษณีย์ไม่ถูกต้อง"
          : "",
        OTHER_PREFIX: !prefixOther ? "โปรดกรอกคำนำหน้าชื่อ" : "",
        QUESTION1: !question1 ? "โปรดกรอกหมายเลขคำสั่งซื้อ" : "",
        QUESTION2: !question2 ? "โปรดเลือกยี่ห้อมือถือที่ใช้อยู่" : "",
        QUESTION3: !question3 ? "โปรดกรอกรุ่นมือถือที่ใช้อยู่" : "",
      };
      setErrorMessage({ ...Cons.ERROR_MESSAGE, ...msg });
      setLoading(false);
      return false;
    } else {
      const payloadRegister = {
        prefixName: prefixOther !== '' ? prefixOther : prefix,
        firstName: firstName?.trim(),
        lastName: lastName?.trim(),
        phoneNumber: phoneNumber,
        postalCode: postalCode,
        question1: question1?.trim(),
        question2: question2,
        question3: question3,
        campaignName: data?.name,
      };

      const fullNameTrim = payloadRegister.firstName.concat(" ", `${payloadRegister.lastName.trim()}`);

      setModalContent(`<p>คุณ ${fullNameTrim} ได้ลงทะเบียนร่วมกิจกรรม</p>${data?.completeText}`);

      swalCustom(
        "ยืนยันการดำเนินการ",
        "คุณต้องการที่จะสมัคร ใช่หรือไม่?",
        async (isConfirmed: any) => {
          if (isConfirmed) {
            setLoading(true);
            // เรียก API เมื่อยืนยัน
            await CanpaignApi.register(payloadRegister)
              .then(async (e) => {
                if (e?.data?.success) {
                  if (PARAMS_CHATCONE_SOCIAL_ID) {
                    const inputMessage = {
                      full_name: fullNameTrim,
                    };
                    const payloadChatcone = {
                      chatcone:
                        PARAMS_CHATCONE_SOCIAL_ID || PARAMS_CHATCONE_TOKEN || PARAMS_CHATCONE_CHANNEL_ID
                          ? {
                              social_id: PARAMS_CHATCONE_SOCIAL_ID || null,
                              token: PARAMS_CHATCONE_TOKEN || null,
                              channel_id: PARAMS_CHATCONE_CHANNEL_ID || null,
                            }
                          : {},
                      process_type: data?.chatcone_process_type,
                      register_id: e?.data?.data?.id,
                      ref_id: e?.data?.data?.id,
                      ref_name: "registration",
                      route_from: `${process.env.REACT_APP_URL}${pathname}`,
                      inputMessage: inputMessage,
                      inputUpdate: {
                        contact_description: {
                          first_name: fullNameTrim,
                        },
                      },
                    };
                    await PartnerApi.sendChatcone(payloadChatcone).catch(async () => {});
                  }
                  setIsShowFinishMoal(true);
                  handleClear();
                }
              })
              .catch((e: any) => {
                return swalError(e?.response?.data?.message || "ระบบมีปัญหากรุณาลองใหม่อีกครั้ง");
              })
              .finally(() => {
                setLoading(false);
              });
          }
        },
        "warning",
        {
          confirmButton: {
            showConfirmButton: true,
            confirmButtonText: "ยืนยัน",
          },
          showCloseButton: false,
        }
      );
    }
  };

  const handleClear = () => {
    setPrefix("");
    setPrefixOther("");
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setPostalCode("");
    setQuestion1("");
    setQuestion2("");
    setQuestion3("");
    setCheckPhonenumber(false);
    setCheckDigiPhonenumber(false);
  };

  return (
    <>
      {!PARAMS_CAMPAIGN_NAME || _.isEmpty(data?.name) ? null : (
        <StyledContainer>
          <Loading show={loading} type="fullLoading" />

          <Grid container justifyContent="center" sx={{ padding: "1rem 0" }}>
            <Grid item {...Cons.GRID_CONTAINER}>
              <Grid container justifyContent="center" spacing={Cons.SPACING}>
                {/* Banner Image */}
                {data?.banner && (
                  <Grid item {...Cons.GRID_BOX_CONTAINER}>
                    <StyledImageBanner src={data.banner} />
                  </Grid>
                )}

                {/* Description */}
                <Grid item {...Cons.GRID_BOX_CONTAINER}>
                  <StyledBoxLeft dangerouslySetInnerHTML={{ __html: data?.description }}></StyledBoxLeft>
                </Grid>

                {/* Form Section */}
                <Grid item {...Cons.GRID_BOX_CONTAINER}>
                  <StyledBoxCenter sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Grid
                      container
                      spacing={Cons.SPACING}
                      sx={{ padding: { xs: "1rem", sm: "1rem", md: "2rem", lg: "2rem" } }}
                    >
                      {/* Personal Info Title */}
                      <Grid item {...Cons.GRID_ITEM_FULL}>
                        <Typography style={{ fontSize: "2rem", textAlign: "left", fontWeight: "600" }}>
                          {"ลงทะเบียนรับสิทธิ์"}
                        </Typography>
                      </Grid>

                      {/* Prefix Autocomplete */}
                      <Grid item {...Cons.GRID_ITEM_2}>
                        <AutocompleteSelect
                          options={optionsPrefix || []}
                          value={_.find(optionsPrefix, (val: any) => val?.value === prefix) || null}
                          placeholder="เลือกคำนำหน้าชื่อ"
                          getOptionLabel={(option: any) => option?.label}
                          labelId="contracttype"
                          onChange={(event, value, reason) => handlePrefixChange(value, reason)}
                          required
                          helperText={errorMessage.PREFIX}
                          heading="คำนำหน้าชื่อ"
                          key="prefix-1"
                        />
                      </Grid>

                      {/* Other Prefix Input */}
                      {prefix === "อื่นๆ" && (
                        <Grid item {...Cons.GRID_ITEM_2}>
                          <InputTextField
                            value={prefixOther || ""}
                            onchange={handlePrefixOtherChange}
                            helperText={errorMessage.OTHER_PREFIX}
                            heading="โปรดระบุ"
                            placeholder="กรอกคำนำหน้าชื่อ"
                            required
                          />
                        </Grid>
                      )}

                      {/* First Name Field */}
                      <Grid item {...(prefix === "อื่นๆ" ? Cons.GRID_ITEM_4 : Cons.GRID_ITEM_5)}>
                        <InputTextField
                          name="firstName"
                          placeholder="กรอกชื่อ"
                          value={firstName || ""}
                          onchange={handleFirstNameChange}
                          helperText={errorMessage.FIRSTNAME}
                          heading="ชื่อ (ภาษาไทย)"
                          required
                        />
                      </Grid>

                      {/* Last Name Field */}
                      <Grid item {...(prefix === "อื่นๆ" ? Cons.GRID_ITEM_4 : Cons.GRID_ITEM_5)}>
                        <InputTextField
                          name="lastName"
                          placeholder="กรอกนามสกุล"
                          value={lastName || ""}
                          onchange={handleLastNameChange}
                          helperText={errorMessage.LASTNAME}
                          heading="นามสกุล (ภาษาไทย)"
                          required
                        />
                      </Grid>

                      {/* Phone Number and Postal Code */}
                      <Grid item {...Cons.GRID_ITEM_HALF}>
                        <InputTextField
                          placeholder="กรอกเบอร์โทรศัพท์"
                          value={phoneNumber || ""}
                          onchange={handlePhoneNumberChange}
                          type="number"
                          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                          helperText={errorMessage.PHONE_NUMBER}
                          heading="เบอร์โทรศัพท์"
                          required
                        />
                      </Grid>

                      <Grid item {...Cons.GRID_ITEM_HALF}>
                        <InputTextField
                          name="postalCode"
                          placeholder="กรอกรหัสไปรษณีย์ที่อยู่ปัจจุบัน"
                          value={postalCode || ""}
                          onchange={handlePostalCodeChange}
                          type="number"
                          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                          helperText={errorMessage.POSTAL_CODE}
                          heading="รหัสไปรษณีย์ที่อยู่ปัจจุบัน"
                          required
                        />
                      </Grid>

                      {/* Order Number */}
                      <Grid item {...Cons.GRID_ITEM_FULL}>
                        <InputTextField
                          placeholder="กรอกหมายเลขคำสั่งซื้อ"
                          value={question1 || ""}
                          onchange={handleQuestion1Change}
                          helperText={errorMessage.QUESTION1}
                          heading="หมายเลขคำสั่งซื้อ"
                          required
                        />
                      </Grid>

                      {/* Phone Brand */}
                      <Grid item {...Cons.GRID_ITEM_HALF}>
                        <AutocompleteSelect
                          options={optionQuestion2 || []}
                          value={_.find(optionQuestion2, (val: any) => val?.value === question2) || null}
                          placeholder="เลือกยี่ห้อมือถือที่ใช้อยู่"
                          getOptionLabel={(option: any) => option?.label}
                          labelId="question2"
                          onChange={handleQuestion2Change}
                          required
                          helperText={errorMessage.QUESTION2}
                          heading="เลือกยี่ห้อมือถือที่ใช้อยู่"
                          key="question2-1"
                        />
                      </Grid>

                      {/* Phone Model */}
                      <Grid item {...Cons.GRID_ITEM_HALF}>
                        <InputTextField
                          placeholder="กรอกรุ่นมือถือที่ใช้อยู่"
                          value={question3 || ""}
                          onchange={handleQuestion3Change}
                          helperText={errorMessage.QUESTION3}
                          heading="รุ่นมือถือที่ใช้อยู่"
                          required
                        />
                      </Grid>

                      {/* Privacy Policy */}
                      <Grid item {...Cons.GRID_ITEM_FULL}>
                        <Typography variant="body2" style={{ textAlign: "center" }}>
                          การกดยืนยันข้อมูล ถือว่าคุณอ่านและรับทราบ&nbsp;
                          <Link
                            color={colors.black}
                            variant="body2"
                            target="_blank"
                            href="https://ufriend.shop/%e0%b8%99%e0%b9%82%e0%b8%a2%e0%b8%9a%e0%b8%b2%e0%b8%a2%e0%b8%84%e0%b8%a7%e0%b8%b2%e0%b8%a1%e0%b9%80%e0%b8%9b%e0%b9%87%e0%b8%99%e0%b8%aa%e0%b9%88%e0%b8%a7%e0%b8%99%e0%b8%95%e0%b8%b1%e0%b8%a7-new/"
                          >
                            นโยบายความคุ้มครองข้อมูลส่วนบุคคล
                          </Link>
                          &nbsp;เรียบร้อยแล้ว
                        </Typography>
                      </Grid>

                      {/* Confirmation Button */}
                      <Grid item {...Cons.GRID_ITEM_FULL}>
                        <ButtonCustom
                          btnStyle={{ width: "100%", fontSize: "1.5rem" }}
                          textButton="ยืนยัน"
                          onClick={onSubmit}
                        />
                      </Grid>
                    </Grid>
                  </StyledBoxCenter>
                </Grid>

                {/* Additional Images */}
                {data?.image_url && (
                  <Grid item {...Cons.GRID_BOX_CONTAINER}>
                    <StyledImageDetail src={data.image_url} />
                  </Grid>
                )}

                {/* Description */}
                <Grid item {...Cons.GRID_BOX_CONTAINER}>
                  <StyledBoxLeft dangerouslySetInnerHTML={{ __html: data?.termsAndConditions || "" }}></StyledBoxLeft>
                </Grid>

                {data?.image_other &&
                  data.image_other.map((image, index) => (
                    <Grid item key={index} {...Cons.GRID_BOX_CONTAINER}>
                      {image?.name === "FB" ? (
                        <Link href="https://www.facebook.com/ufriendth" target="_blank">
                          <StyledImageOther src={image?.url} />
                        </Link>
                      ) : (
                        <StyledImageOther src={image?.url} />
                      )}
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>

          <ModalFilmFocusFinish
            isShowModal={isShowFinishModal}
            setIsShowMoal={setIsShowFinishMoal}
            content={modalContent}
          />
        </StyledContainer>
      )}
    </>
  );
};

export default FilmFocus;
