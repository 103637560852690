import { styled } from "@mui/material/styles";
import {  Box } from "@mui/material";

export const StyledContainer = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  backgroundColor: "#399FDA",
}));

export const StyledBoxLeft = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  backgroundColor: "#ffffff",
  padding: "1rem",
  textAlign: "left",
  borderRadius: "10px",
  fontFamily: "Prompt",
  fontSize: "1rem",
  lineHeight: "1.5rem",
}));

export const StyledBoxCenter = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  backgroundColor: "#ffffff",
  padding: "1rem",
  textAlign: "center",
  borderRadius: "10px",
  fontFamily: "Prompt",
  fontSize: "1rem",
  lineHeight: "1.5rem",
}));

export const StyledImageBanner = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
  maxWidth: "100%",
  borderRadius: 20,
}));

export const StyledImageDetail = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
  maxWidth: "100%",
  borderRadius: 20,
}));

export const StyledImageOther = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
  maxWidth: "100%",
  borderRadius: 20,
}));
