import BaseAPICampaign from "./api-campaign";
interface masterDataParams {
  code: string;
  name?: string;
  is_active?: boolean;
  is_visible?: boolean;
}

interface campaignList {
  _id?: string;
  name?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  termsAndConditions?: string;
  notes?: string;
  status?: string;
  question1?: string;
  question2?: string;
  question3?: string;
  image_url?: string;
  image_other?: [
    {
      url: string;
    }
  ];
  banner?: string;
  completeText?: string;
}

export default class CampaignApi extends BaseAPICampaign {
  static async phoneBrand(param: masterDataParams): Promise<any> {
    return this.api.get(`/masterdata`, { params: { ...param } }).then((res) => res);
  }

  static async list(param: campaignList): Promise<any> {
    return this.api.get(`/campaign/list`, { params: { ...param } }).then((res) => res);
  }

  static async register(payload: any): Promise<any> {
    return this.api.post(`/campaign/register`, payload).then((res) => res);
  }
}
