export const defaultDataRegister =
{
    productHand: 'NEW',
    productGroup: 'iPhone',
    acceptCondition: false,
    walkin: false,
    job: {
        type: 'บุคคลทั่วไป'
    },
    // type: "REFINANCE",
}


export const defaultDataRegisterRefin =
// {
//     productHand: 'NEW',
//     productGroup: 'iPhone',
//     acceptCondition: false,
//     walkin: false,
//     job: {
//         type: 'บุคคลทั่วไป'
//     },
//     // type: "REFINANCE",
// }

{
    "productHand": "NEW",
    "productGroup": "iPhone",
    "acceptCondition": false,
    "walkin": false,
    "type": "REFINANCE",
    "chatcone": null,
    // "chatcone": {
    //     "social_id": "U5faad2143d7a2b3839d8689c996840d4",
    //     "token": "f52a99c6e079ec03de10c0e7d062a561b29222d599d4ee8e0e408e83e27fa5806cf019ae1b17093ae55d134ff22d74b5956de16a3847556f31046e6182603d018d1aa749a7573ea4ce1471b455d6d27176b9ff79fecf2abf5a54d586a33ada83137495f473a691e7a548ca6108b1ec2495",
    //     "channel_id": "1"
    // },
    "profile": {
        "type": "REFINANCE",
        "prefix": "นาย",
        "firstName": "เทสกัน",
        "lastName": "ไอโฟนแลกเงิน",
        "zipcode": "11110",
        "phoneNumber": "0983333222",
        "idCard": "8403274238963",
        "address": "เทส",
        "subdistrict": "บางคูรัด",
        "district": "บางบัวทอง",
        "province": "นนทบุรี",
        "facebook": "gmlgml",
        "phoneSystem": "เติมเงิน",
        "dateOfBirth": "09/09/1991",
        "serial_number": "test",
        "imeiImages": {
            "type": "REFINANCE",
            "images": [
                {
                    "fileName": "ufriend-register/register_19o8cgwb/1732521088288.jpeg",
                    "mimeType": "image/jpeg",
                    "name": "314661375_564059742387291_9106255137994938047_n.jpg",
                    "img": "blob:http://localhost:3000/64b4d799-afd3-4283-a098-19a005ac82d9"
                }
            ]
        },
        "iphoneStorage": {
            "type": "REFINANCE",
            "images": [
                {
                    "fileName": "ufriend-register/register_n6t1xri9/1732521091632.jpeg",
                    "mimeType": "image/jpeg",
                    "name": "314661375_564059742387291_9106255137994938047_n.jpg",
                    "img": "blob:http://localhost:3000/ef5a3cd7-7bd8-497a-b0d5-867b3ebfba14"
                }
            ]
        }
    },
    // "chatcone": null,
    "product": {
        "model": "iPhone 11",
        "storage": "128GB",
        "color": "Black"
    },
    "installments": 6,
    "interestedBranch": "CENTER",
    "combo": [],
    "statement": {
        "images": [
            {
                // "fileName": "ufriend-register/register_chvo2a62/1732521079875.jpeg",
                // "mimeType": "image/jpeg",
                "name": "66435242_2571978982835046_9191697756853370880_n.jpg",
                "img": "blob:http://localhost:3000/83c2ccc9-21c2-4862-b115-6116054ff13f"
            }
        ],
        "type": "CURRENT",
        "month": "",
        "password": ""
    },
    "contact": [
        {
            "relationship": "พ่อแม่",
            "firstName": "ห",
            "lastName": "ก",
            "phoneNumber": "0981111110"
        },
        {
            "relationship": "พ่อแม่",
            "firstName": "หห",
            "lastName": "กก",
            "phoneNumber": "0981111111"
        },
        {
            "relationship": "พ่อแม่",
            "firstName": "หหห",
            "lastName": "กกก",
            "phoneNumber": "0981111112"
        }
    ],
    "knownUfriendFrom": {
        "value": "CAR_ADVERTISE",
        "label": "ป้ายโฆษณาติดรถ"
    },
    "othersDetails": "",
    "job": {
        "type": "นักเรียน/นักศึกษา",
        "salary": "7000",
        "college": "rwa",
        "faculty": "ff",
        "branch": "ff",
        "degree": "ปวส",
        "year": "3555",
        "grade": "3",
        "chanelSalary": "พ่อแม่ หรือผู้ปกครอง",
        "studentImages": [
            {
                "fileName": "ufriend-register/register_v494m1ho/1732521072408.jpeg",
                "mimeType": "image/jpeg",
                "name": "314661375_564059742387291_9106255137994938047_n.jpg",
                "img": "blob:http://localhost:3000/3e533251-baff-4fa8-bcae-fe11402fde83"
            }
        ],
        "transcriptOrTermFees": [
            {
                "fileName": "ufriend-register/register_qoxhz3l6/1732521075962.jpeg",
                "mimeType": "image/jpeg",
                "name": "66435242_2571978982835046_9191697756853370880_n.jpg",
                "img": "blob:http://localhost:3000/7613edd8-ac5d-4335-b1d4-abb46a56f5da"
            }
        ]
    }
}


export const defaultDataRegisterInstallMent =

{
    "productHand": "NEW",
    "productGroup": "iPhone",
    "acceptCondition": false,
    "walkin": false,
    "type": "INSTALLMENT",
    "profile": {
        "type": "INSTALLMENT",
        "prefix": "นาย",
        "firstName": "เทสกัน",
        "lastName": "ไอโฟนแลกเงิน",
        "zipcode": "11110",
        "phoneNumber": "0983333222",
        "idCard": "8403274238963",
        "address": "เทส",
        "subdistrict": "บางคูรัด",
        "district": "บางบัวทอง",
        "province": "นนทบุรี",
        "facebook": "gmlgml",
        "phoneSystem": "เติมเงิน",
        "dateOfBirth": "09/09/1991",
    },
    "chatcone": null,
    "product": {
        "model": "iPhone 11",
        "storage": "128GB",
        "color": "Black"
    },
    "installments": 6,
    "interestedBranch": "CENTER",
    "combo": [],
    "statement": {
        "images": [
            {
                "fileName": "ufriend-register/register_chvo2a62/1732521079875.jpeg",
                "mimeType": "image/jpeg",
                "name": "66435242_2571978982835046_9191697756853370880_n.jpg",
                "img": "blob:http://localhost:3000/83c2ccc9-21c2-4862-b115-6116054ff13f"
            }
        ],
        "type": "CURRENT",
        "month": "",
        "password": ""
    },
    "contact": [
        {
            "relationship": "พ่อแม่",
            "firstName": "ห",
            "lastName": "ก",
            "phoneNumber": "0981111110"
        },
        {
            "relationship": "พ่อแม่",
            "firstName": "หห",
            "lastName": "กก",
            "phoneNumber": "0981111111"
        },
        {
            "relationship": "พ่อแม่",
            "firstName": "หหห",
            "lastName": "กกก",
            "phoneNumber": "0981111112"
        }
    ],
    "knownUfriendFrom": {
        "value": "CAR_ADVERTISE",
        "label": "ป้ายโฆษณาติดรถ"
    },
    "othersDetails": "",
    "job": {
        "type": "นักเรียน/นักศึกษา",
        "salary": "7000",
        "college": "rwa",
        "faculty": "ff",
        "branch": "ff",
        "degree": "ปวส",
        "year": "3555",
        "grade": "3",
        "chanelSalary": "พ่อแม่ หรือผู้ปกครอง",
        "studentImages": [
            {
                "fileName": "ufriend-register/register_v494m1ho/1732521072408.jpeg",
                "mimeType": "image/jpeg",
                "name": "314661375_564059742387291_9106255137994938047_n.jpg",
                "img": "blob:http://localhost:3000/3e533251-baff-4fa8-bcae-fe11402fde83"
            }
        ],
        "transcriptOrTermFees": [
            {
                "fileName": "ufriend-register/register_qoxhz3l6/1732521075962.jpeg",
                "mimeType": "image/jpeg",
                "name": "66435242_2571978982835046_9191697756853370880_n.jpg",
                "img": "blob:http://localhost:3000/7613edd8-ac5d-4335-b1d4-abb46a56f5da"
            }
        ]
    }
}

export const REGISTER_TYPE_TH = {
    INSTALLMENT: "ผ่อน",
    REFINANCE: "แลกเงิน",
};
