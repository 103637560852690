import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import _, { values } from "lodash";
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import InstallMentApi from '../../../api/installment/installment.api';
import OptionsApi from '../../../api/option/option.api';
import ButtonCustom from '../../../components/button/buttonCustom';
import PreviewImage from '../../../components/image/previewImage';
import HelperText from '../../../components/input/helperText';
import InputTextField from '../../../components/input/inputTextField';
import AutocompleteSelect from '../../../components/select/autoCompleteSelect';
import { colors } from '../../../constants/colors';
import { numberFormat } from '../../../utils/common';
import { optionsPeriod } from '../../../utils/options';
import { fromRegister } from '../type-register';
import * as Styles from './layout-product.style';
import { amplitudeTrack } from '../../../utils/amplitude/amplitude';
import useWindowSize from '../../../utils/useWindowSize';
import { useLocation } from 'react-router-dom';

const initStateErrorMessage = {
  PRODUCT_HAND: ``,
  PRODUCT_GROUP: ``,
  PRODUCT_MODEL: ``,
  PRODUCT_STORAGE: ``,
  PRODUCT_COLOR: ``,
  PRODUCT_INSTALLMENTS: ``,
  PRODUCT_COMBO: ``,
  PRODUCT_BRANCH: ``,
}

interface props {
  data?: any
  checkedItems?: any
  onClickNext: () => void;
  onChange?: (value: any) => void
  downPaymentVal?: (value: any) => void
  checkedItemsVal?: (value: any) => void
}
export default function LayoutProduct(props: props) {
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)
  const [data, setData] = useState<fromRegister>(props.data)
  const [width, height] = useWindowSize()
  const [type, setType] = useState<string>(props.data?.type || 'INSTALLMENT' || '-')
  const [productHand, setProductHand] = useState<string>(props.data?.productHand)
  const [productGroup, setProuctGroup] = useState<string>(props.data?.productGroup)
  const [productModel, setProductModel] = useState<any[]>([])
  const [product, setProduct] = useState<string>(props.data?.product?.model)
  const [storageModel, setStorageModel] = useState<any[]>([])
  const [storage, setStorage] = useState<string>(props.data?.product?.storage)
  const [branchData, setBranchData] = useState<any[]>([])
  const [branch, setBranch] = useState<string>(props.data?.interestedBranch)
  const [colorModel, setColorModel] = useState<any[]>([])
  const [color, setColor] = useState<string>(props.data?.product?.color)
  const [friendAdvisorCode, setFriendAdvisorCode] = useState<string>(props.data?.friend_advisor_code)
  const [installments, setInstallMents] = useState<number>(0)
  const [preiod, setPreiod] = useState<number>(props.data?.installments)
  const [downPayment, setDownPayment] = useState<string>('0')
  // const [downPaymentAss, setDownPaymentAss] = useState<number>(0)
  const [max, setMax] = useState<string>('0')

  const [productComboData, setProductComboData] = useState<any[]>([])
  const [productCombo, setProductCombo] = useState<any[]>(props.data?.combo || [])

  const [optionsPeriodChoice, setOptionsPeriodChoice] = useState<any[]>(optionsPeriod)

  const [checkedItems, setCheckedItems] = useState<any[]>(props.checkedItems || []);
  const [productCombCheckboxData, setProductComboCheckboxData] = useState<any[]>([])
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const PARAMS_TYPE = params.get('type');
  const PARAMS_CATEGORY = params.get('category');
  const PARAMS_MODEL = params.get('model');
  const PARAMS_STORAGE = params.get('storage');
  const PARAMS_COLOR = params.get('color');

  useEffect(() => {
    if (props.onChange) {
      props.onChange(data)
    }
    if (props.downPaymentVal) {
      props.downPaymentVal(Number(downPayment))
      // props.downPaymentVal(Number(downPayment) + Number(downPaymentAss))
    }
    if (props.checkedItemsVal) {
      props.checkedItemsVal(checkedItems)
    }
  }, [data])

  useEffect(() => {
    let group
    switch (PARAMS_CATEGORY) {
      case 'Smartphone':
        group = 'iPhone'
        break;
      case 'Tablet':
        group = 'iPad'
        break;
      default:
        break;
    }
    if (PARAMS_MODEL && !props.data?.product?.model) {
      setProduct(PARAMS_MODEL || '')
    }
    if (PARAMS_STORAGE && !props.data?.product?.storage) {
      setStorage(PARAMS_STORAGE || '')
    }
    if (PARAMS_COLOR && !props.data?.product?.color) {
      setColor(PARAMS_COLOR || '')
    }
    if (PARAMS_CATEGORY && !props.data?.product?.productGroup) {
      setProuctGroup(group || 'iPhone')
    }
  }, [PARAMS_CATEGORY, PARAMS_MODEL, PARAMS_STORAGE, PARAMS_COLOR]);

  const loadProduct = useCallback(() => {
    let condition: any = {}
    if (type) condition = { ...condition, type: type }
    if (productGroup) condition = { ...condition, category: productGroup === 'iPhone' ? 'Smartphone' : 'Tablet' }
    if (productHand) condition = { ...condition, productHand: productHand }
    OptionsApi.productModel(condition).then((res) => {
      if (res.status === 200) {
        setProductModel(res.data.model)
      }
    })
  }, [type, productGroup, productHand])

  useEffect(() => {
    loadProduct()
  }, [loadProduct]);

  const loadBranch = useCallback(() => {
    let condition: any = {}
    if (type) {
      condition = { ...condition, type: productHand === 'SECOND' ? 'SECOND' : type }
      if (type === 'REFINANCE') {
        setOptionsPeriodChoice([])
      } else if (type === 'INSTALLMENT') {
        setOptionsPeriodChoice(optionsPeriod)
      }
    }
    OptionsApi.registerBranch(condition).then((res) => {
      if (res.status === 200) {
        setBranchData(res.data.result)
      }
    })

  }, [type, productHand])

  useEffect(() => {
    loadBranch()
  }, [loadBranch]);

  useEffect(() => {
    if (product) {
      let condition: any = {}
      if (type) condition = { ...condition, type: type }
      if (product) condition = { ...condition, model: product }
      if (productHand) condition = { ...condition, productHand: productHand }
      OptionsApi.productStorage(condition).then((res) => {
        if (res.status === 200) {
          setStorageModel(res.data.storage)
        }
      })
    }
  }, [product]);

  useEffect(() => {
    if (type && product && storage) {
      let condition: any = {}
      if (type) condition = { ...condition, type: type }
      if (product) condition = { ...condition, model: product }
      if (storage) condition = { ...condition, storage: storage }
      if (productHand) condition = { ...condition, productHand: productHand }
      OptionsApi.productColor(condition).then((res) => {
        if (res.status === 200) {
          setColorModel(res.data.color)
        }
      })
      if (!props.data?.installments) {
        setPreiod(0)
      }
    }
  }, [storage]);

  useEffect(() => {
    if (product && storage && productGroup === 'iPad' && type !== 'REFINANCE') {
      let condition: any = {}
      if (product) condition = { ...condition, name: product }
      if (storage) condition = { ...condition, storage: storage }
      OptionsApi.productCombo(condition).then((res) => {
        if (res.status === 200) {
          setProductComboData(res.data.result)
          setProductComboCheckboxData(res.data.result.checkbox)
        }
      })
    }
  }, [productModel, storage]);

  useEffect(() => {
    if (type
      && preiod
      && product
      && storage
      && color) {
      calculate()
    }
  }, [preiod, productCombo]);

  useEffect(() => {
    if (type
      && product
      && storage
      && color && type === 'REFINANCE') {
      calculate()
    }
  }, [storage, color]);

  const calculate = async () => {
    let types =
      (type === 'INSTALLMENT' && productHand === 'NEW') ? 'INSTALLMENT' :
        (type === 'INSTALLMENT' && productHand === 'SECOND') ? 'INSTALLMENTS_SPECIAL' : 'REFINANCE'
    const payload = {
      type: types,
      product: {
        model: product,
        storage: storage,
        color: color
      },
      period: preiod,
      combo: productCombo
    }
    const res = await InstallMentApi.calculate(payload)
    if (res.status === 200) {
      setDownPayment(res.data.downPayment)
      if (props.downPaymentVal) props.downPaymentVal(res.data.downPayment)
      setMax(res.data.maximum)
      if (type === 'REFINANCE') {
        const period = res.data.installmentRange.map((e: any) => {
          return {
            value: e.period,
            label: String(e.period)
          }
        })
        setOptionsPeriodChoice(period)
        if (preiod) {
          let installmentFilter = res.data.installmentRange.filter(
            (el: any) => el.period === preiod,
          );
          setInstallMents(installmentFilter[0]?.installments || 0)
        }
      } else {
        setInstallMents(res.data.installments)
      }
      amplitudeTrack('1_Register_P_price', product + ',' + storage + ',' + preiod + ',' + productCombo.map((e: any) => { return e.name + ',' }))
    }
  };

  // useEffect(() => {
  //   if (productCombo.length > 0) {
  //     let iPrice: number = 0
  //     for (const item of productCombo) {
  //       iPrice += item.down_amount
  //     }
  //     setDownPaymentAss(iPrice)
  //   }
  // }, [productCombo]);

  const clearData = () => {
    setProductHand(type === 'REFINANCE' ? 'NEW' : '')
    setErrorMessage(initStateErrorMessage)
    setProuctGroup('iPhone')
    setProductModel([])
    setProduct('')
    setStorageModel([])
    setStorage('')
    setBranchData([])
    setBranch('')
    setColorModel([])
    setColor('')
    setFriendAdvisorCode('')
    setInstallMents(0)
    setPreiod(0)
    setDownPayment('0')
    // setDownPaymentAss(0)
    setMax('0')
    setCheckedItems([])
    setProductCombo([])
    setProductComboData([])
    setProductComboCheckboxData([])
    if (props.downPaymentVal) props.downPaymentVal(0)
  }

  const clearDataProductHand = () => {
    setProuctGroup('iPhone')
    setErrorMessage(initStateErrorMessage)
    setProduct('')
    setStorageModel([])
    setStorage('')
    setBranch('')
    setColorModel([])
    setColor('')
    setFriendAdvisorCode('')
    setInstallMents(0)
    setPreiod(0)
    setDownPayment('0')
    // setDownPaymentAss(0)
    setMax('0')
    setCheckedItems([])
    setProductCombo([])
    setProductComboData([])
    setProductComboCheckboxData([])
    if (props.downPaymentVal) props.downPaymentVal(0)
  }

  const clearDataProductGroup = () => {
    setErrorMessage(initStateErrorMessage)
    setProductModel([])
    setProduct('')
    setStorageModel([])
    setStorage('')
    setBranch('')
    setColorModel([])
    setColor('')
    setFriendAdvisorCode('')
    setInstallMents(0)
    setPreiod(0)
    setDownPayment('0')
    // setDownPaymentAss(0)
    setMax('0')
    setCheckedItems([])
    setProductCombo([])
    setProductComboData([])
    setProductComboCheckboxData([])
    if (props.downPaymentVal) props.downPaymentVal(0)
  }

  const clearDataProduct = () => {
    setStorage('')
    setColor('')
    setInstallMents(0)
    setDownPayment('0')
    // setDownPaymentAss(0)
    setStorageModel([])
    setColorModel([])
    setPreiod(0)
    setMax('0')
    setCheckedItems([])
    setProductCombo([])
    setProductComboData([])
    setProductComboCheckboxData([])
  }

  const clearDataStorage = () => {
    setColor('')
    setInstallMents(0)
    setDownPayment('0')
    // setDownPaymentAss(0)
    setColorModel([])
    setPreiod(0)
    setMax('0')
    setCheckedItems([])
    setProductCombo([])
    setProductComboData([])
    setProductComboCheckboxData([])
  }

  const clearDataColor = () => {
    setInstallMents(0)
    setDownPayment('0')
    setPreiod(0)
    setMax('0')
    // setCheckedItems([])
    // setProductCombo([])
    // setProductComboData([])
    // setProductComboCheckboxData([])
  }

  const onSubmit = async () => {
    if (!product || !storage || !color || !preiod || !branch || (checkedItems.length > 0 && _.isEmpty(productCombo))) {
      const msg: any = {
        PRODUCT_MODEL: !product ? 'โปรดเลือกสินค้า' : '',
        PRODUCT_STORAGE: !storage ? 'โปรดเลือกความจุ' : '',
        PRODUCT_COLOR: !color ? 'โปรดเลือกสี' : '',
        PRODUCT_INSTALLMENTS: !preiod ? 'โปรดเลือกงวด' : '',
        PRODUCT_COMBO: (checkedItems.length > 0 && _.isEmpty(productCombo)) ? 'โปรดเลือกสินค้า' : '',
        PRODUCT_BRANCH: !branch ? 'โปรดเลือกสาขาที่สนใจ' : '',
      }
      setErrorMessage({ ...initStateErrorMessage, ...msg })
      return false
    } else {
      // amplitudeTrack('1_Product_N_next')
      setData({
        ...data, product: {
          model: product,
          storage: storage,
          color: color
        },
        productGroup: productGroup,
        productHand: productHand || 'NEW',
        installments: preiod,
        interestedBranch: branch,
        friend_advisor_code: friendAdvisorCode,
        combo: productCombo
      })
      amplitudeTrack('1_Register_N_next')
      setTimeout(() => {
        return props.onClickNext && props.onClickNext()
      }, 500);
    }
  }

  const handleChange = (event: any, name: string) => {
    const { checked } = event.target;
    if (checked) {
      setCheckedItems([...checkedItems, { value: name }]);
    } else {
      setCheckedItems(checkedItems.filter((e: any) => e.value !== name))
      setProductCombo(productCombo.filter((e: any) => e.type !== name))
      // setDownPaymentAss(0)
    }
  };

  const handleChangeProdcuctCombo = (value: any, type: any, reason: any) => {
    const data = {
      "name": value.name,
      "code": value.code,
      "down_amount": value.down_amount,
      "type": type.value
    }
    if (reason === 'clear') {
      const data = productCombo.filter((i: any) => i.type !== type.value)
      setProductCombo(data)
      // setDownPaymentAss(0)
    } else {

    }
  };

  return (
    <Styles.Container>
      <div >
        <RadioGroup
          aria-labelledby="controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          row
          value={type}
          onChange={(e: any) => {
            setType(e.target.value)
            clearData()
            setData({ ...data, type: e.target.value, profile: { ...data.profile, type: e.target.value } })
            amplitudeTrack(`${e.target.value === 'INSTALLMENT' ? '1_Register_I_switch_to_lease' : '1_Register_I_switch_to_refin'}`)
          }}
          className='justify-content-center'
        >
          <FormControlLabel
            value="INSTALLMENT"
            control={<Radio
              sx={(theme) => ({
                '& .MuiSvgIcon-root': {
                  [theme.breakpoints.down('xl')]: {
                    fontSize: 24,
                  },
                  [theme.breakpoints.down('lg')]: {
                    fontSize: 24,
                  },
                  [theme.breakpoints.down('md')]: {
                    fontSize: 30,
                  }
                },
              })}
            />}
            label={<div className='font-22 font-extra-bold'>
              ผ่อนสินค้า
            </div>}
          />
          <FormControlLabel
            value="REFINANCE"
            control={<Radio
              sx={(theme) => ({
                '& .MuiSvgIcon-root': {
                  [theme.breakpoints.down('xl')]: {
                    fontSize: 24,
                  },
                  [theme.breakpoints.down('lg')]: {
                    fontSize: 24,
                  },
                  [theme.breakpoints.down('md')]: {
                    fontSize: 30,
                  }
                },
              })}
              disabled={PARAMS_TYPE === 'DOWNPAYMENT'}
            />}
            label={<div className='font-22 font-extra-bold'>
              ไอโฟนแลกเงิน
            </div>}
          />
        </RadioGroup>
      </div>

      <Styles.MarginRow>
        <Col lg={6} sm={6} xs={12}>
          <PreviewImage disabled={width <= 575 ? true : false}
            image={
              color ? _.find(colorModel, (val: any) => val.value === color)?.image :
                productModel ? _.find(productModel, (val: any) => val.value === product)?.image : ''
            }
          />
        </Col>
        <Col lg={6} sm={6} xs={12}>
          {type === 'INSTALLMENT' && (
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              row
              value={productHand}
              onChange={(e: any) => {
                clearDataProductHand()
                setProductHand(e.target.value)
                // amplitudeTrack(`${e.target.value === 'INSTALLMENT' ? '1_Product_I_lease' : '1_Product_I_refin'}`, '')
              }}
            >
              <Col xs={6} md={6}>
                <FormControlLabel
                  value="NEW"
                  control={<Radio
                    sx={(theme) => ({
                      '& .MuiSvgIcon-root': {
                        [theme.breakpoints.down('xl')]: {
                          fontSize: 24,
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: 24,
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: 30,
                        }
                      },
                    })}
                  />}
                  label="สินค้ามือหนึ่ง"
                />
              </Col>
              <Col xs={6} md={6}>
                <FormControlLabel
                  value="SECOND"
                  control={<Radio
                    sx={(theme) => ({
                      '& .MuiSvgIcon-root': {
                        [theme.breakpoints.down('xl')]: {
                          fontSize: 24,
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: 24,
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: 30,
                        }
                      },
                    })}
                    disabled={PARAMS_TYPE === 'DOWNPAYMENT'}
                  />}
                  label="สินค้ามือสอง"
                />
              </Col>
            </RadioGroup>
          )
          }
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            row
            value={productGroup}
            onChange={(e: any) => {
              clearDataProductGroup()
              setProuctGroup(e.target.value)
            }}
          >
            <Col xs={6} md={6}>
              <FormControlLabel
                value="iPhone"
                control={<Radio
                  sx={(theme) => ({
                    '& .MuiSvgIcon-root': {
                      [theme.breakpoints.down('xl')]: {
                        fontSize: 24,
                      },
                      [theme.breakpoints.down('lg')]: {
                        fontSize: 24,
                      },
                      [theme.breakpoints.down('md')]: {
                        fontSize: 30,
                      }
                    },
                  })}
                />}
                label="iPhone"
              />
            </Col>
            <Col xs={6} md={6}>
              <FormControlLabel
                value="iPad"
                control={<Radio
                  sx={(theme) => ({
                    '& .MuiSvgIcon-root': {
                      [theme.breakpoints.down('xl')]: {
                        fontSize: 24,
                      },
                      [theme.breakpoints.down('lg')]: {
                        fontSize: 24,
                      },
                      [theme.breakpoints.down('md')]: {
                        fontSize: 30,
                      }
                    },
                  })}
                />}
                label="iPad"
              />
            </Col>
          </RadioGroup>
          <Styles.MarginRow>
            <AutocompleteSelect
              options={productModel || []}
              value={_.find(productModel, (val: any) => val.value === product) || null}
              placeholder={'เลือกสินค้า'}
              getOptionLabel={(option: any) => option.label}
              labelId="product"
              onChange={(event, value, reason) => {
                if (value) {
                  setErrorMessage({ ...errorMessage, PRODUCT_MODEL: '' })
                  setProduct(value.value)
                  // amplitudeTrack(`${'1_Product_P_model-' + value.value}`, value.value)
                  clearDataProduct()
                } else if (reason === 'clear') {
                  setProduct('')
                  clearDataProduct()
                }
              }}
              heading='สินค้า'
              helperText={errorMessage.PRODUCT_MODEL}
              required
            />
          </Styles.MarginRow>
          <Row>
            <Col xs={12} lg={6} className='pt-2'>
              <AutocompleteSelect
                options={storageModel || []}
                value={_.find(storageModel, (val: any) => val.value === storage) || null}
                placeholder={'เลือกความจุ'}
                getOptionLabel={(option: any) => option.label}
                labelId="contracttype"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, PRODUCT_STORAGE: '' })
                    setStorage(value.value)
                    // amplitudeTrack(`${'1_Product_P_capacity'}`, value.value)
                    clearDataStorage()
                  } else if (reason === 'clear') {
                    setStorage('')
                    clearDataStorage()
                  }
                }}
                heading='ความจุ'
                required
                helperText={errorMessage.PRODUCT_STORAGE}
              />
            </Col>
            <Col xs={12} lg={6} className='pt-2'>
              <AutocompleteSelect
                options={colorModel || []}
                value={_.find(colorModel, (val: any) => val.value === color) || null}
                placeholder={'เลือกสี'}
                getOptionLabel={(option: any) => option.label}
                labelId="contracttype"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, PRODUCT_COLOR: '' })
                    // amplitudeTrack(`${'1_Product_P_color'}`, value.value)
                    setColor(value.value)
                    clearDataColor()
                  } else if (reason === 'clear') {
                    setColor('')
                    clearDataColor()
                  }
                }}
                heading='สี'
                required
                helperText={errorMessage.PRODUCT_COLOR}
              />
            </Col>
          </Row>

          <Styles.MarginRow>
            <div><span>งวด</span><span style={{ color: colors.danger }}>*</span></div>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              row
              value={_.find(optionsPeriodChoice, (val: any) => val.value === preiod)?.value || null}
              onChange={(e: any) => {
                if (e) {
                  setErrorMessage({ ...errorMessage, PRODUCT_INSTALLMENTS: '' })
                  setPreiod(parseInt(e.target.value))
                  // amplitudeTrack(`${'1_Product_P_period'}`, e.target.value)
                }
              }}
            >
              {optionsPeriodChoice.map((el: any) => {
                return (
                  // <Col xs={el.label === '12 *นักศึกษาเท่านั้น' ? 6 : 3} md={6} >
                  <FormControlLabel
                    value={el.value}
                    control={<Radio
                      sx={(theme) => ({
                        '& .MuiSvgIcon-root': {
                          [theme.breakpoints.down('xl')]: {
                            fontSize: 24,
                          },
                          [theme.breakpoints.down('lg')]: {
                            fontSize: 24,
                          },
                          [theme.breakpoints.down('md')]: {
                            fontSize: 30,
                          }
                        },
                      })} />}
                    label={el.label}
                  />
                  // </Col>
                )
              })}
            </RadioGroup>
            {errorMessage.PRODUCT_INSTALLMENTS !== '' &&
              <div>
                <HelperText label={errorMessage.PRODUCT_INSTALLMENTS} />
              </div>
            }
          </Styles.MarginRow>

          {(productCombCheckboxData?.length > 0) && (
            <Styles.MarginRow>
              <>
                <div>
                  อุปกรณ์เสริม
                </div>
                {productCombCheckboxData.map((item: any) => {
                  return (
                    <FormControlLabel control={
                      <Checkbox checked={_.find(checkedItems, (val: any) => val.value === item.values ? true : false) || false}
                        onChange={(e: any) => {
                          handleChange(e, item.values)
                        }
                        }
                      />
                    }
                      label={item.values}
                    />
                  )
                })}

              </>
            </Styles.MarginRow>
          )
          }
          {(checkedItems) && (
            checkedItems.map((e: any) => {
              return (
                <Styles.MarginRow>
                  <AutocompleteSelect
                    options={productComboData[e.value] || []}
                    value={_.find(productComboData[e.value], (val: any) =>
                      _.find(productCombo, (e: any) => e.name === val.name) || null)
                      || null
                    }
                    placeholder={'เลือก Pencil'}
                    getOptionLabel={(option: any) => option.name}
                    labelId="contracttype"
                    onChange={(event, value, reason) => {
                      if (value) {
                        const data = {
                          "name": value.name,
                          "code": value.code,
                          "down_amount": value.down_amount,
                          "type": e.value
                        }
                        setErrorMessage({ ...errorMessage, PRODUCT_COMBO: '' })
                        // productCombo.filter((i: any) => i.code !== value.code)
                        // setProductCombo(productCombo.filter((i: any) => i.code !== value.code))
                        setProductCombo([data])
                      } else if (reason === 'clear') {
                        setProductCombo(productCombo.filter((i: any) => i.type !== e.value))
                        // setDownPaymentAss(0)
                      }
                    }}
                    heading={e.value}
                    helperText={errorMessage.PRODUCT_COMBO}
                    required
                  />
                </Styles.MarginRow>
              )
            })
          )}

          <Styles.MarginRow>
            <Col xs={12} lg={6} className='mt-2'>
              <InputTextField
                value={numberFormat(Number(downPayment) || Number(max))}
                // value={numberFormat(Number(downPayment) + Number(downPaymentAss) || Number(max))}
                heading={type === 'INSTALLMENT' ? 'จำนวนเงินดาวน์' : 'วงเงินสูงสุด'}
                disabled
              />
            </Col>
            <Col xs={12} lg={6} className='mt-2'>
              <InputTextField
                value={numberFormat(installments)}
                heading='จำนวนเงินผ่อน'
                disabled
              />
            </Col>
          </Styles.MarginRow>
          <Styles.MarginRow>
            <AutocompleteSelect
              options={branchData || []}
              value={_.find(branchData, (val: any) => val.value === branch) || null}
              placeholder={'เลือกสาขา'}
              groupBy={(option: any) => option.region}
              getOptionLabel={(option: any) => option.label}
              labelId="contracttype"
              onChange={(event, value, reason) => {
                if (value) {
                  setErrorMessage({ ...errorMessage, PRODUCT_BRANCH: '' })
                  setBranch(value.value)
                  amplitudeTrack(`${'1_Register_P_interestedBranch'}`, value.value)
                } else if (reason === 'clear') (
                  setBranch('')
                )
              }}
              heading='สาขาที่สนใจ'
              helperText={errorMessage.PRODUCT_BRANCH}
              required
            />
          </Styles.MarginRow>
          <Styles.MarginRow>
            <Col xs={12}>
              <InputTextField
                value={friendAdvisorCode}
                onchange={(event: any) => {
                  setFriendAdvisorCode(event.target.value)
                }}
                onBlur={() => {
                  if (friendAdvisorCode)
                    amplitudeTrack('1_Register_P_fgf', friendAdvisorCode)
                }}
                heading='รหัสผู้แนะนำ (ถ้ามี)'
              />
            </Col>
          </Styles.MarginRow>
          {
            type === 'REFINANCE' && (
              <div className='font-14 pt-4' style={{ color: colors.red }}>
                <div>
                  * การประเมินราคาเป็นไปตามเงื่อนไขของทางบริษัท หรือขึ้นอยู่กับสภาพเครื่องของลูกค้า
                </div>
                <div>
                  * iPhone 11 ขอสงวนสิทธิ์เฉพาะลูกค้าเก่าที่เข้าเงื่อนไขของบริษัทเท่านั้น
                </div>
              </div>
            )
          }
          <Styles.CenterContainer>
            <ButtonCustom
              btnStyle={{ width: '100%' }}
              className={"mt-2"}
              textButton={'ยืนยัน'}
              onClick={() => { onSubmit() }}
            />
          </Styles.CenterContainer>
        </Col>
      </Styles.MarginRow >

      {/* </Col> */}
      {/* </Styles.MarginRow > */}

    </Styles.Container >
  );
}
