export const ERROR_MESSAGE = {
  PREFIX: ``,
  OTHER_PREFIX: ``,
  FIRSTNAME: ``,
  LASTNAME: ``,
  PHONE_NUMBER: ``,
  POSTAL_CODE: ``,
  QUESTION1: ``,
  QUESTION2: ``,
  QUESTION3: ``,
};

export const SPACING = 3;

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px

export const GRID_CONTAINER = {
  xs: 11,
  sm: 10,
  md: 9,
  lg: 7,
};

export const GRID_BOX_CONTAINER = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
};

export const GRID_FORM_CONTAINER = {
  xs: 12,
  sm: 11,
  md: 11,
  lg: 11,
};

export const GRID_ITEM_2 = {
  xs: 12,
  sm: 4,
  md: 2,
  lg: 2,
};

export const GRID_ITEM_3 = {
  xs: 12,
  sm: 3,
  md: 3,
  lg: 3,
};

export const GRID_ITEM_4 = {
  xs: 12,
  sm: 3,
  md: 4,
  lg: 4,
};

export const GRID_ITEM_5 = {
  xs: 12,
  sm: 4,
  md: 5,
  lg: 5,
};

export const GRID_ITEM_HALF = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 6,
};

export const GRID_ITEM_FULL = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
};
