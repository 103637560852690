import BaseAPIPartner from "../api-partner"

export default class PartnerApi extends BaseAPIPartner {
  static sendChatcone(payload: any): Promise<any> {
    return this.api.post(`/send-chatcone`, payload).then((res) => res)
  }

  static sendChatconeByProcess(payload: any): Promise<any> {
    return this.api.post(`/send-chatcone-by-process`, payload).then((res) => res)
  }
}
