export const numberFormat = (num: any, minDigits = 2, maxDigits = 2) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
  return formatter.format(num);
};

export const toBuddhistYear = (moment: any, format: string) => {
  const christianYear = moment.format("YYYY");
  const buddhishYear = (parseInt(christianYear) + 543).toString();
  return moment
    .format(
      format
        .replace("YYYY", buddhishYear)
        .replace("YY", buddhishYear.substring(2, 4))
    )
    .replace(christianYear, buddhishYear);
};

export const chkDigitPid = (p_iPID: string) => {
  let total = 0;
  let iPID;
  let chk;
  let Validchk;
  iPID = p_iPID.replace(/-/g, "");
  Validchk = iPID.substr(12, 1);
  let j = 0;
  let pidcut;
  for (let n = 0; n < 12; n++) {
    pidcut = parseInt(iPID.substr(j, 1));
    total = (total + ((pidcut) * (13 - n)));
    j++;
  }
  chk = 11 - (total % 11);
  if (chk === 10) {
    chk = 0;
  } else if (chk === 11) {
    chk = 1;
  }
  if (chk === Number(Validchk)) {
    return true;
  } else {
    return false;
  }
}

export const checkPattenPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber.startsWith('06') && !phoneNumber.startsWith('08') && !phoneNumber.startsWith('09')) {
    return false
  }
  const pattern = /(09)|(08)|(06)[.\- ]?[0-9]/;
  let result = pattern.test(phoneNumber)
  return result
}

export const checkSpecialCharacter = (value: string) => {
  const formats = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  if (formats.test(value)) {
    return false
  } else {
    return true
  }
}

export const checkThaiLang = (value: string) => {
  const format = /^[ก-๙ || ]+$/
  if (format.test(value)) {
    return true
  } else {
    return false
  }
}

export const checkEngLang = (value: string) => {
  const format = /^[A-Za-z || !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~ || 0-9 ||]+$/
  if (format.test(value)) {
    return true
  } else {
    return false
  }
}


